a {text-decoration: none; }

.navbar-toggler{
  border: 0;
}

.video-container {
  height: 0;
  margin-top: 59px;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.img-thumbnail {
  width: 100%;
  background-color: inherit;
  padding: 0;
  border: 1px solid transparent;
  border-radius: 1rem;
}

#root{
  background-color: inherit;
  background: inherit;
}

.portfolio{
  position: relative;
  text-align: center; 
  border-radius: 0.5rem;
}

input,
textarea {
  color: inherit;
  background-color: inherit;
}

#photos {
  /* Prevent vertical gaps */
  line-height: 0;

  -webkit-column-count: 5;
  -webkit-column-gap: 0px;
  -moz-column-count: 5;
  -moz-column-gap: 0px;
  column-count: 3;
  column-gap: 0px;
}

#photos img {
  /* Just in case there are inline attributes */
  width: 100% !important;
  height: auto !important;
}

.react-switch svg {
  position: relative;
  vertical-align: baseline;
  color: #202124;
  left: 3px;
}

.fa-circle {
  color: white;
}

@media (max-width: 1200px) {
  #photos {
    -moz-column-count: 4;
    -webkit-column-count: 4;
    column-count: 4;
  }
}
@media (max-width: 1000px) {
  #photos {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}
@media (max-width: 800px) {
  #photos {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}
@media (max-width: 400px) {
  #photos {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }

  #footer {
    height: 100px !important;
  }

  section {
    padding-bottom: 100px !important;
  }

  #section {
    margin-bottom: 100px !important;
    width: 50% !important;
  }
}
